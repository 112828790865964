import './styles.scss'

import { Col } from 'react-bootstrap'
import Quote from '@Assets/icon-quote_3.svg'
import React from 'react'

export default function Testimonial({ text, name, location }) {

  return <Col className='testimonial text-center text-secondary' md='4' sm='4'>
    {text && <div className='testimonial-text'>
      <div className='testimonial-icon'>
        <img src={Quote} alt='testimonial-icon' />
      </div>
      {text}
    </div>}
    {name && <div className='testimonial-name'>{name}</div>}
    {location && <div className='testimonial-location'>{location}</div>}
  </Col>
}
