import '@Components/Pages/About/styles.scss'

import { Col, Row } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Handshake from '@Assets/icon-hands.png'
import LineItem from '@Components/Pages/About/LineItem'
import MenuHeader from '@Components/shared/MenuPieces/MenuHeader'
import Purchase from '@Assets/icon-purchase.png'
import React from 'react'
import Reserve from '@Assets/icon-reserve.png'
import Search from '@Assets/icon-search.png'
import StaticTestimonials from '@Components/shared/Testimonial/StaticTestimonials'
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons'

const About = () =>
  <div className='menu-page'>
    <div className='about' lg={{ span: 10, offset: 1 }} md={{ span: 12, offset: 0 }}>
      <div className='about-header'>
        <Row className='justify-content-center about-header-small'>
          ABOUT
          </Row>
        <Col md={{ span: 8, offset: 2 }}>
          <MenuHeader title={'Corporate Fleet Vehicles at Discounted Prices.'} />
        </Col>
      </div>
      <div className='about-info mt-4'>
        <hr className='about-line' />
        <Row className='text-center icon-row'>
          <LineItem className='join' text='JOIN' image={Handshake} />
          <LineItem className='search' text='SEARCH' image={Search} />
          <LineItem className='reserve' text='RESERVE' image={Reserve} />
          <LineItem className='purchase' text='PURCHASE' image={Purchase} />
        </Row>
        <Row className='about-info-text'>
          <Col lg={{ span: 5, offset: 1 }}>
            CarVantedge, owned and offered by Fleet Street Remarketing, provides an opportunity
            for privileged customers to purchase expertly maintained corporate fleet vehicles.
            The vehicles we are offering for sale have been used by corporations for their employees
            such as sales people, technicians, and executives.
            </Col>
          <Col lg={5}>
            So, what does it mean to be privileged? This opportunity is not available to everyone.
            If you are a member or employee of an affiliated organization that has partnered with
            Fleet Street Remarketing, then you are eligible to purchase vehicles through this program
            at highly discounted prices.
            </Col>
        </Row>
      </div>
      <div className='about-testimonials'>
        <div className=' about-testimonials-header text-center'>TESTIMONIALS</div>
        <StaticTestimonials />
      </div>
      <div className='about-change'>
        <div className='about-change-icon'><FontAwesomeIcon icon={faClipboardCheck} /></div>
        <MenuHeader title='3 Days to change your mind' />
        <Row>
          <Col className='about-change-text' lg={{ span: 4, offset: 4 }} md={{ span: 8, offset: 2 }} sm={{ span: 8, offset: 2 }}>
            Our vehicles all come with a money-back guarantee of 3 days or 150 miles, whichever comes first so that you can buy with confidence and peace of mind.
            </Col>
        </Row>
      </div>
    </div>
  </div>

export default About