import { Col } from 'react-bootstrap'
import React from 'react'

const LineItem = ({ image, text, className }) =>
    <Col className={`about-info-active ${className}`}>
        <img src={image} alt={text} />
        <div className='dot'>•</div>
        <div className='about-info-active-text'>{text}</div>
    </Col>

export default LineItem