import React, { Fragment } from 'react'

import MenuHeader from '../MenuPieces/MenuHeader'
import { Row } from 'react-bootstrap'
import Testimonial from './Testimonial'

export default function StaticTestimonials() {
    return <Fragment>
        <MenuHeader title='What People Say' />
        <Row>
            <Testimonial
                text='Thank you so much for making this car purchase experience painless and pleasant. I was skeptical about buying online at first, but your team really put me at ease. I will definitely be buying my next vehicle from CarVantedge!'
                name='Suzanne'
                location='NY'
            />
            <Testimonial
                text={'I just wanted to take a minute to say "THANK YOU" for making the purchase of my car - a Chevy Cruse - such an enjoyable experience! I\'ve got the car .... I\'ve got the title .... and everything was taken care of quickly and easily! I wanted to tell you that I have appreciated your help through the buying process!'}
                name='Garry'
                location='GA'
            />
            <Testimonial
                text={'We made it home this afternoon from picking up our car from your distribution center ... looks great! Just needs a little body work (but we knew that.) Happy to see all of the car\'s paper work in the glove box. Thanks for all you did to make our transaction run so smoothly!'}
                name='Kirk'
                location='CA'
            />
        </Row>
    </Fragment>
}